import request from "@/utils/request";
import store from "@/store/index";
import axios from "axios";
import { messageError, getHeader } from "./utils";

export function getRouter() {
    return store.getters.router;
}

// 获取系统配置信息
export function getConfig(path, key) {
    if (key) {
        return request({
            url: `/config/${path}/${key}`,
            method: "get",
        });
    } else {
        return request({
            url: `/config/${path}`,
            method: "get",
        });
    }
}

// 视图访问接口
export function view(app, view, data = {}, page = 0, size = 1000) {
    return request({
        url: `/${getRouter().view}/${app}/${view}?page=${page}&size=${size}`,
        method: "post",
        data: data,
    });
}

// 模型多比查询访问接口
export function collect(app, model, data = {}, page = 0, size = 1000) {
    return request({
        url: `/${getRouter().collect}/${app}/${model}?page=${page}&size=${size}`,
        method: "post",
        data: data,
    });
}

// 导出视图数据
export async function exportView(app, view, data = {}, otherParams, size = 1000, page = 0) {
    if (!app || !view) return;
    let url = `/${getRouter().export}/${app}/${view}`;
    if (size > 0) {
        url = url + `?size=${size}&page=${page}`;
    }
    if (otherParams) {
        url = url + otherParams;
    }
    let header = {
        "content-type": "application/json;charset=utf-8",
        "x-frame-options": "SAMEORIGIN",
    };
    header = getHeader() != {} && getHeader() != undefined ? Object.assign(header, getHeader()) : header;
    axios({
        method: "POST",
        headers: header,
        url: url,
        responseType: "blob",
        data: data,
    })
        .then(function (success) {
            console.log("success", success);
            let downloadData = success.data;
            if (!downloadData) return;
            let downloadUrl = window.URL.createObjectURL(new Blob([downloadData]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = downloadUrl;
            link.setAttribute("download", success.headers.filename);
            if (data.filename && data.filename != "" && data.filename != null && data.filename != undefined) {
                link.setAttribute("download", decodeURIComponent(success.headers.filename));
            }
            document.body.appendChild(link);
            link.click();
        })
        .catch(function (error) {
            messageError(error.response.data.message);
        });
}

// 导出数据/下载模板
export async function download(url, data = {}) {
    if (!url) return;
    // 如果是视图并且要加入size与page参数, 在url后拼接: url?size=1000&page=2
    let header = {
        "content-type": "application/json;charset=utf-8",
        "x-frame-options": "SAMEORIGIN",
    };
    header = getHeader() != {} && getHeader() != undefined ? Object.assign(header, getHeader()) : header;
    axios({
        method: "POST",
        headers: header,
        url: url,
        responseType: "blob",
        data: data,
    })
        .then(function (success) {
            console.log("success", success);
            let downloadData = success.data;
            if (!downloadData) return;
            let downloadUrl = window.URL.createObjectURL(new Blob([downloadData]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = downloadUrl;
            link.setAttribute("download", success.headers.filename);
            //自定义名称适配中文(名字在后端进行URL编码处理，这边解码)
            if (data.template_name != undefined && data.template_name != null && data.template_name != "") {
                link.setAttribute("download", decodeURIComponent(success.headers.filename));
            }
            document.body.appendChild(link);
            link.click();
        })
        .catch(function (error) {
            messageError(error.response.data.message);
        });
}

// PDF导出数据
export async function pdfFillDownload(data = {}, app, api) {
    if (!data) return;
    let url
    if (!app || !api) {
        url = `/app/bis/pdfDownload`;
    } else {
        url = `/${getRouter().app}/${app}/${api}`;
    }
    let header = {
        "content-type": "application/json;charset=utf-8",
        "x-frame-options": "SAMEORIGIN",
    };
    header = getHeader() != {} && getHeader() != undefined ? Object.assign(header, getHeader()) : header;
    axios({
        method: "POST",
        headers: header,
        url: url,
        responseType: "blob",
        data: data,
    })
        .then(function (success) {
            let downloadData = success.data;
            if (!downloadData) return;
            let downloadUrl = window.URL.createObjectURL(new Blob([downloadData]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = downloadUrl;
            link.setAttribute("download", success.headers.filename);
            //自定义名称适配中文(名字在后端进行URL编码处理，这边解码)
            if (data.fileName != undefined && data.fileName != null && data.fileName != "") {
                link.setAttribute("download", decodeURIComponent(success.headers.filename));
            }
            document.body.appendChild(link);
            link.click();
        })
        .catch(function (error) {
            messageError(error.response.data.message);
        });
}

// 导入视图数据
export async function importView(app, view, callback, otherParams) {
    var input = document.createElement("input");
    input.style.width = "100%";
    input.type = "file"; //添加file类型
    // input.accept=".pdf"; //限制只能上传PDF文件
    input.style.display = "none";
    input.id = "files";
    document.body.appendChild(input);
    input.click();
    input.onchange = async (event) => {
        let formData = new FormData();
        formData.append("files", event.target.files[0]);
        let url = `/${getRouter().import}/${app}/${view}`;
        if (otherParams) {
            url = url + otherParams;
        }
        document.body.removeChild(input);
        const res = await request({
            url: url,
            method: "post",
            data: formData,
        });
        callback(res);
    };
}

// 获取单个模型实例
export function modelGetByUid(app, model, uid) {
    if (uid == undefined) {
        return request({
            url: `/${getRouter().model}/${app}/${model}`,
            method: "get",
        });
    } else {
        return request({
            url: `/${getRouter().model}/${app}/${model}/${uid}`,
            method: "get",
        });
    }
}

// 删除单个模型实例
export function modelDeleteByUid(app, model, uid) {
    return request({
        url: `/${getRouter().model}/${app}/${model}/${uid}`,
        method: "delete",
    });
}

// 批量删除模型实例
export function modelDeleteByUids(app, model, uids) {
    return request({
        url: `/${getRouter().model}/${app}/${model}`,
        method: "delete",
        data: uids,
    });
}

// 新建或保存单个或多个模型实例（依次单个保存）
export function modelPost(app, model, data = {}) {
    return request({
        url: `/${getRouter().model}/${app}/${model}`,
        method: "post",
        data: data,
    });
}

// 访问模型自定义接口 get
export function modelApiGet(app, model, api, query) {
    return request({
        url: `/${getRouter().api}/${app}/${model}/${api}` + (query ? "?" + query : ""),
        method: "get",
    });
}

// 访问模型自定义接口 post
export function modelApiPost(app, model, api, data = {}) {
    return request({
        url: `/${getRouter().api}/${app}/${model}/${api}`,
        method: "post",
        data: data,
    });
}

// 访问登录页面
export function loginPost(data = {}) {
    return request({
        url: `/${getRouter().login}`,
        method: "post",
        data: data,
    });
}

// 访问登出页面
export function logoutPost(data = {}) {
    return request({
        url: `/${getRouter().logout}`,
        method: "post",
        data: data,
    });
}

// 访问模型自定义接口 put
export function modelApiPut(app, model, api, data = {}, query) {
    return request({
        url: `/${getRouter().api}/${app}/${model}/${api}` + (query ? "?" + query : ""),
        method: "put",
        data: data,
    });
}

// 访问模型自定义接口 delete
export function modelApiDelete(app, model, api, query) {
    return request({
        url: `/${getRouter().api}/${app}/${model}/${api}` + (query ? "?" + query : ""),
        method: "delete",
    });
}

// 访问原生接口 get
export function apiGet(app, api, query) {
    return request({
        url: `/${getRouter().app}/${app}/${api}` + (query ? "?" + query : ""),
        method: "get",
    });
}

// 访问原生接口 post
export function apiPost(app, api, data = {}) {
    return request({
        url: `/${getRouter().app}/${app}/${api}`,
        method: "post",
        data: data,
    });
}

// 访问原生接口 put
export function apiPut(app, api, data = {}, query) {
    return request({
        url: `/${getRouter().app}/${app}/${api}` + (query ? "?" + query : ""),
        method: "put",
        data: data,
    });
}

// 访问原生接口 delete
export function apiDelete(app, model, api, query) {
    return request({
        url: `/${getRouter().app}/${app}/${api}` + (query ? "?" + query : ""),
        method: "delete",
    });
}

// 文件上传方法
export function upload(data = {}) {
    return request({
        url: `/${getRouter().upload}`,
        method: "post",
        data: data,
    });
}

// 执行规则引擎
export function ruleApiPost(app, code, data = {}) {
    return request({
        url: `/${getRouter().rule}/${app}/${code}`,
        method: "post",
        data: data,
    });
}

export function getField(fApi) {
    let rule = fApi.rule;
    while (rule) {
        if (rule.field) {
            return rule.field;
        }
        if (!rule.__fc__.parent) {
            return "";
        }
        rule = rule.__fc__.parent.rule;
    }
}
