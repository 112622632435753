<template>
  <div id="app">
    <div v-wechat-title="$route.meta.title" />
    <router-view />
  </div>
</template>
<script>
// import "@/assets/css/icon.css";
import "@/assets/css/index.css";
import "@/assets/css/lg.css";
import "@/assets/css/md.css";
import "@/assets/css/xs.css";
import "@/assets/iconfont/iconfont.css";
export default {
  
};
</script>

